.row_coming_soon
  align-items: center
  background: hsl(220, 10, 98)
  box-sizing: border-box
  display: flex
  height: 40vh
  height: 500px
  justify-content: center
  overflow-x: hidden
  position: relative
  text-align: center
  width: 100vw

  .top_bar
    background: linear-gradient(to right, hsl(200, 100, 50), hsl(190, 100, 50))
    height: 8px
    left: 0
    position: fixed
    top: 0
    width: 100vw
    z-index: 500

  .inner
    min-width: 600px
    position: relative

    img.rocket
      margin-bottom: 20px

    img.patterns
      left: 50%
      margin-left: -400px
      position: absolute
      top: 20px
      z-index: 0

    h1
      color: black
      font-size: 24pt
      font-weight: 900
      line-height: 24pt
      margin-bottom: 40px
      position: relative
      text-align: center
      text-transform: uppercase
      width: 100%
      z-index: 50

    a.button
      $height: 44px
      background: hsl(200, 100, 50)
      border-radius: $height
      box-shadow: 0 2px 16px hsla(200, 100, 50, 0.4)
      color: white
      display: block
      display: inline-block
      font-size: 16px
      font-weight: 700
      height: $height
      line-height: $height
      margin-bottom: 80px
      padding: 0 $height
      position: relative
      text-decoration: none
      transition-duration: 0.2s
      transition-property: background
      transition-timing-function: ease-out
      z-index: 50

      &:hover
        background: hsl(220, 100, 50)
      
      &:active
        background: black