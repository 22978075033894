@keyframes site_overlay_animate_in
  from
    opacity: 0
  to
    opacity: 1

@keyframes site_overlay_animate_out
  from
    opacity: 1
  to
    opacity: 0

.site_overlay
  background: hsla(0, 0, 100, 0)
  box-sizing: border-box
  display: none
  height: 100vh
  left: 0
  overflow-y: auto
  position: fixed
  top: 0
  width: 100vw
  z-index: 100
  padding: 40px 0

  &._active
    display: block

  &._animate_in
    animation-duration: 0.2s
    animation-fill-mode: forwards
    animation-name: site_overlay_animate_in
    animation-timing-function: ease-out

  &._animate_out
    animation-duration: 0.2s
    animation-fill-mode: forwards
    animation-name: site_overlay_animate_out
    animation-timing-function: ease-out