@import reset

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,700i,800')

$font_family_0: 'Open Sans', 'Helvetica Neue', 'Helvetica', Arial, sans-serif

html
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: antialiased
  font-family: $font_family_0
  text-rendering: geometricPrecision
  text-rendering: optimizeLegibility

body

%input
  appearance: none
  border: 0
  box-sizing: border-box
  display: block
  font-family: $font_family_0
  font-size: 16px
  resize: none
  width: 100%

  &:focus
    outline: none

@import site_content
@import site_overlay

@import modal_contact
@import coming_soon
@import banners

@import site_footer