$site_footer_height: 72px

.site_footer
  background: hsl(200, 5, 10)
  box-sizing: border-box
  width: 100%

  .inner
    box-sizing: border-box
    color: white
    margin: 0 auto
    max-width: 1024px + 80px
    padding: 20px 40px
    text-align: center
    width: 100%

    .copyright
      font-size: 14px
      line-height: 20px
      font-weight: 600
      color: hsl(200, 100, 50)
      color: white