.row_banners
  box-sizing: border-box
  min-height: calc(100vh - 500px)
  position: relative

  >.inner
    box-sizing: border-box
    margin: 0 auto
    padding-top: 40px
    text-align: center
    width: 1024px + 80px

    @media screen and (max-width: 1024px + 80px)
      width: 100%

    h2
      font-size: 24px
      font-style: italic
      font-weight: 800
      line-height: 28px
      margin-bottom: 20px
      text-align: center

    ul.banners
      $banner_height: 120px
      $banner_width: 240px
      $gutter: 20px
      box-sizing: border-box
      display: inline-block
      font-size: 0
      margin-bottom: 20px
      padding: $gutter / 2
      text-align: left
      width: ($banner_width + $gutter) * 3 + $gutter

      @media screen and (max-width: ($banner_width + $gutter) * 3 + $gutter)
        width: ($banner_width + $gutter) * 2 + $gutter

      @media screen and (max-width: ($banner_width + $gutter) * 2 + $gutter)
        text-align: center
        width: 100%

      li
        display: inline-block
        margin: $gutter / 2

        a:link, a:visited
          cursor: pointer
          display: block

        img
          background: hsl(0, 0, 95)
          border-radius: 8px
          box-shadow: 0 1px 32px 0 hsla(0, 0, 0, 0.1)
          cursor: pointer
          display: inline-block
          height: $banner_height
          width: $banner_width