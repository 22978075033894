@keyframes modal_contact_animate_in
  from
    transform: translateY(10px)
    opacity: 0
  to
    transform: translateY(0px)
    opacity: 1

@keyframes modal_contact_animate_out
  from
    transform: translateY(0px)
    opacity: 1
  to
    transform: translateY(10px)
    opacity: 0

.modal_contact
  background: white
  border-radius: 8px
  box-shadow: 0 0 32pt hsla(0, 0, 0, 0.2)
  box-sizing: border-box
  display: none
  margin: 0 auto
  margin-top: 40px
  max-width: 460px
  padding: 40px
  position: relative
  width: 100%
  z-index: 110

  @media screen and (max-width: 500px)
    width: 100vw
    border-radius: 0
    margin-top: 0
    min-height: 100vh
    padding: 40px 20px

  &._active
    display: block

  &._animate_in
    animation-duration: 0.2s
    animation-fill-mode: forwards
    animation-name: modal_contact_animate_in
    animation-timing-function: ease-out

  &._animate_out
    animation-duration: 0.2s
    animation-fill-mode: forwards
    animation-name: modal_contact_animate_out
    animation-timing-function: ease-out

  a.close
    position: absolute
    top: 8px
    right: 8px

    @media screen and (max-width: 500px)
      top: 12px
      right: 12px

  h2
    font-size: 24px
    font-weight: bold
    margin-bottom: 20px
  
  .input_group
    margin-bottom: 20px

  label
    color: hsl(0, 0, 40)
    display: block
    font-family: $font_family_0
    font-size: 14px
    font-weight: 600
    line-height: 18px
    margin-bottom: 8px

  input, textarea, button
    @extend %input

  input, textarea
    background: hsl(0, 0, 95)
    border-radius: 8px
    box-sizing: border-box
    color: hsl(0, 0, 10)
    font-size: 16px
    font-weight: 600
    padding: 1em
    transition-duration: 0.2s
    transition-property: background
    transition-timing-function: ease-out

    &:focus
      background: hsl(0, 0, 92)
  
  textarea
    min-height: calc(2em + 40px)
    line-height: 20px

  input
    height: 44px
    line-height: 20px
    padding: 11px 1em
  
  .button
    background: hsl(200, 100, 50)
    border-radius: 44px
    color: white
    cursor: pointer
    font-weight: 700
    height: 44px
    transition-duration: 0.2s
    transition-property: background
    transition-timing-function: ease-out

    &:hover
      background: hsl(220, 100, 50)
    
    &:active
      background: black
  
  label.label_error
    color: hsl(340, 100, 60)
    font-size: 12px
    font-style: normal
    font-weight: 600
    line-height: 18px
    margin-top: 8px

  .hidden
    display: none

  #submit_message
    background: hsl(170, 100, 90)
    color: hsl(170, 100, 10)
    border-radius: 8px
    margin-top: 20px
    font-weight: 600
    font-size: 14px
    line-height: 18px
    padding: 0.5em 1em
    text-align: center

    &._success
      background: hsl(170, 100, 90)
      color: hsl(170, 20, 50)

    &._error
      background: hsl(350, 100, 95)
      color: hsl(350, 100, 50)

  